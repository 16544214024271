export const environment = {
  production: false,
  authDomain: 'https://admin-dev2.linkroom.com/__/auth/handler',
  adminLinkroom: 'https://admin-dev2.linkroom.com',
  api: 'https://admindev-api.linkroom.com',
  client: 'https://dev.linkroom.com',
  firebase: {
    projectId: 'linkroom-v2-dev',
    appId: '1:383066649579:web:19c4ead8fa9b62525a1fae',
    storageBucket: 'linkroom-v2-dev.appspot.com',
    locationId: 'us-east1',
    apiKey: 'AIzaSyAmHvWxoIoLv41qJVXeIGlo6Y-Jbn_K6DI',
    authDomain: 'admin-dev2.linkroom.com',
    messagingSenderId: '383066649579',
    measurementId: 'G-5HW1Y5YHWW'
  },
  gallery: 'https://gallery-dev.linkroom.com',
  vipAdmin: 'https://fxpadmin-dev.linkroom.com',
  sceneEditor: 'https://linkroom-editorv2.web.app',
  stripe: {
    clientId: 'ca_PeyMoZazotdiSjC0p7wIVqaLkcrv5Gjh',
    key: 'pk_test_51MwSWlG8SGFDayonaMDGntqMTtH9Cupr3Z59yLLIhPkYzJjiJrW1hseyAIr6VJMAI8UxPzxcrF8JXumfBnj4ufeN00e9mxM41Y'
  }
};
