import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { InviteService } from '@features/services/invite.service';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { RoleService } from '@features/services/role.service';
import { ModalService } from '@shared/services/modal.service';
import { validateForm } from '@shared/utility/global.functions';

@Component({
  selector: 'app-add-code',
  templateUrl: './add-code.component.html',
  styleUrls: ['./add-code.component.scss']
})
export class AddCodeComponent implements OnInit {
  group!: FormGroup;
  loading = false;

  constructor(
    public authService: AuthService,
    private builder: FormBuilder,
    private inviteService: InviteService,
    private modalService: ModalService,
    private organizationService: OrganizationService,
    private profileService: ProfileService,
    private roleService: RoleService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  async onSubmit() {
    if (this.group.valid) {
      this.loading = true;
      const control = this.group.controls['code'];
      control.disable();

      try {
        const invite = await lastValueFrom(this.inviteService.get(this.group.value.code));
        console.log(invite);
        if (!invite.data || !invite.data.active) {
          this.showModal('error', control, 'invites.errors.invite-error');
          return;
        }

        const [organization, role] = await Promise.all([
          lastValueFrom(this.organizationService.get(invite.data.organization)),
          lastValueFrom(this.roleService.get(invite.data.role))
        ]);

        if (
          !organization.data ||
          !role.data ||
          this.authService.user?.email !== invite.data.email
        ) {
          this.showModal('error', control, 'invites.errors.invalid-invite');
          return;
        }

        await Promise.all([
          lastValueFrom(
            this.profileService.setOrganization(this.authService.user._id, {
              _id: organization.data._id,
              name: organization.data.name,
              roleId: role.data._id,
              roleName: role.data.name
            })
          ),
          lastValueFrom(
            this.organizationService.setProfile(organization.data._id, {
              _id: this.authService.user._id,
              active: this.authService.user.active,
              email: this.authService.user.email,
              firstName: this.authService.user.firstName,
              lastName: this.authService.user.lastName
            })
          ),
          lastValueFrom(
            this.organizationService.deactivateInvite(organization.data._id, invite.data._id)
          )
        ]);

        this.showModal('success', control, 'invites.modal.success');
        this.authService.refresh();
        control.reset();
      } catch (error) {
        this.showModal('error', control, 'invites.errors.invite-error');
      }
    } else {
      validateForm(this.group);
    }
  }

  private initForm() {
    this.group = this.builder.group({
      code: [null, [Validators.required]]
    });
  }

  private showModal(action: 'error' | 'success', control: AbstractControl, key: string) {
    this.translate.get(key).subscribe((translation: string) => {
      switch (action) {
        case 'error':
          this.modalService.onError(translation);
          break;
        case 'success':
          this.modalService.onSuccess(translation);
          break;
      }

      this.loading = false;
      control.enable();
    });
  }
}
