import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { environment } from 'src/environments/environment';

import { FirestoreDocument } from '@shared/models/firestore-document';
import { Result } from '@shared/models/result.model';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { WORLD } from '@shared/utility/global.constants';

import { SkyboxViewModel } from '../models/skybox.model';
import {
   _ComplementaryFiles,
   _OptionalFiles,
   _Scene,
   SceneViewModel,
   World,
   WorldCollectionToShare,
   WorldSharedViewModel,
   WorldViewModel
} from '../models/world.model';

@Injectable({
   providedIn: 'root'
})
export class WorldService {
   constructor(
      private db: AngularFirestore,
      private localStorageService: LocalStorageService,
      private http: HttpClient
   ) { }

   deactivateInOrg(organizationId: string, worldId: string) {
      return this.http.patch<Result<void>>(
         `${environment.api}${WORLD.endpoint}/deactivate/${organizationId}/${worldId}`,
         {}
      );
   }

   activateInOrg(worldId: string, organizationId: string) {
      return this.http.patch<Result<void>>(
         `${environment.api}${WORLD.endpoint}/activate/organization/${worldId}`,
         organizationId
      );
   }

   addWorld(data: World & FirestoreDocument) {
      const form = new FormData();
      form.append('_id', data._id);
      form.append('name', data.name);
      form.append('description', data.description);
      form.append('active', data.active.toString());
      if (data.v2) form.append('v2', data.v2.toString());
      if (data.owner) form.append('owner', data.owner);
      if (data.ownerId) form.append('ownerId', data.ownerId);
      if (data.file) form.append('file', data.file);
      if (data.fileName) form.append('fileName', data.fileName);

      return this.http.post<Result<void>>(`${environment.api}${WORLD.endpoint}/add`, form);
   }

   addWorldInOrg(orgId: string, data: World & FirestoreDocument) {
      return this.http.post<Result<void>>(`${environment.api}${WORLD.endpoint}/add/${orgId}`, data);
   }

   shareWorld(worldId: string, dto: WorldCollectionToShare, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.post<Result<void>>(
         `${environment.api}${WORLD.endpoint}/share/${worldId}`,
         dto,
         {
            params
         }
      );
   }

   listSharedUsers(worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<WorldSharedViewModel[]>>(
         `${environment.api}${WORLD.endpoint}/list-share/${worldId}`,
         { params }
      );
   }

   deleteSharedUsers(id: string, worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<WorldSharedViewModel[]>>(
         `${environment.api}${WORLD.endpoint}/delete-share/${worldId}/${id}`,
         { params }
      );
   }

   updateWorld(data: World & FirestoreDocument) {
      const form = new FormData();
      form.append('_id', data._id);
      form.append('name', data.name);
      form.append('description', data.description);
      form.append('active', data.active.toString());
      if (data.v2) form.append('v2', data.v2.toString());
      if (data.owner) form.append('owner', data.owner);
      if (data.ownerId) form.append('ownerId', data.ownerId);
      if (data.file) form.append('file', data.file);
      if (data.fileName) form.append('fileName', data.fileName);
      return this.http.put<Result<void>>(
         `${environment.api}${WORLD.endpoint}/update/${data._id}`,
         form
      );
   }

   deleteWorld(worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.delete<Result<void>>(`${environment.api}${WORLD.endpoint}/delete/${worldId}`, {
         params
      });
   }

   deleteWorldV3(worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.delete<Result<void>>(`${environment.api}${WORLD.endpoint}/v3/world/delete/${worldId}`, {
         params
      });
   }

   toggleWorldActive(worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.patch<Result<void>>(
         `${environment.api}${WORLD.endpoint}/${worldId}`,
         {},
         { params }
      );
   }

   get(worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<WorldViewModel | undefined>>(
         `${environment.api}${WORLD.endpoint}/${worldId}`,
         { params }
      );
   }

   getScenes(worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<_Scene[] | undefined>>(
         `${environment.api}${WORLD.endpoint}/scenes/${worldId}`,
         { params }
      );
   }

   listScenes(worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<SceneViewModel[]>>(
         `${environment.api}${WORLD.endpoint}/list/scenes/${worldId}`,
         { params }
      );
   }

   addScene2(scene: SceneViewModel, worldId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.post<Result<void>>(
         `${environment.api}${WORLD.endpoint}/scene/add/${worldId}`,
         scene,
         {
            params
         }
      );
   }

   getScene(worldId: string, sceneId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<SceneViewModel | undefined>>(
         `${environment.api}${WORLD.endpoint}/scene/${worldId}/${sceneId}`,
         { params }
      );
   }

   deleteSceneV2(worldId: string, sceneId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.delete<Result<void>>(
         `${environment.api}${WORLD.endpoint}/scene/delete/${worldId}/${sceneId}`,
         { params }
      );
   }

   deleteSceneV3(worldId: string, sceneId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.delete<Result<void>>(
         `${environment.api}${WORLD.endpoint}/v3/scene/delete/${worldId}/${sceneId}`,
         { params }
      );
   }

   updateSceneName(worldId: string, sceneId: string, scene: Partial<SceneViewModel>,
      owner?: string,
      ownerId?: string) {
      const url = `${environment.api}${WORLD.endpoint}/update-scene-name/${worldId}/${sceneId}`

      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.put<Result<SceneViewModel | undefined>>(
         url,
         scene,
         { params }
      );
   }

   updateScene2(
      worldId: string,
      sceneId: string,
      scene: SceneViewModel,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.put<Result<SceneViewModel | undefined>>(
         `${environment.api}${WORLD.endpoint}/update/scene/${worldId}/${sceneId}`,
         scene,
         { params }
      );
   }

   updateScenePublished(
      worldId: string,
      sceneId: string,
      published: boolean,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.put<Result<void>>(
         `${environment.api}${WORLD.endpoint}/update/scene/published/${worldId}/${sceneId}/${published}`,
         {},
         { params }
      );
   }

   list() {
      return this.http.get<Result<WorldViewModel[]>>(`${environment.api}${WORLD.endpoint}`);
   }

   addSkybox(
      skybox: SkyboxViewModel,
      worldId: string,
      sceneId: string,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.post<Result<void>>(
         `${environment.api}${WORLD.endpoint}/skybox/add/${worldId}/${sceneId}`,
         skybox,
         { params }
      );
   }

   deleteSkybox(
      worldId: string,
      sceneId: string,
      skyboxId: string,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.delete<Result<void>>(
         `${environment.api}${WORLD.endpoint}/skyboxes/${worldId}/${sceneId}/${skyboxId}`,
         { params }
      );
   }

   listSkyboxes(worldId: string, sceneId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<SkyboxViewModel[]>>(
         `${environment.api}${WORLD.endpoint}/skyboxes/${worldId}/${sceneId}`,
         { params }
      );
   }

   getScenesWithSkybox(skyboxId: string) {
      return this.http.get<Result<SceneViewModel[]>>(
         `${environment.api}${WORLD.endpoint}/skyboxes/scenes/${skyboxId}`
      );
   }

   GetWorldInScene(sceneId: string) {
      return this.http.get<Result<WorldViewModel>>(
         `${environment.api}${WORLD.endpoint}/skyboxes/scenes/worlds/${sceneId}`
      );
   }

   listCompFiles(worldId: string, sceneId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<_ComplementaryFiles[]>>(
         `${environment.api}${WORLD.endpoint}/new/compFiles/${worldId}/${sceneId}`,
         { params }
      );
   }

   listOptFiles(worldId: string, sceneId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<_OptionalFiles[]>>(
         `${environment.api}${WORLD.endpoint}/new/optFiles/${worldId}/${sceneId}`,
         { params }
      );
   }

   addOptFileNew(
      worldId: string,
      sceneId: string,
      data: _OptionalFiles & FirestoreDocument,
      owner?: string,
      ownerId?: string
   ) {
      console.log(data.order);
      const form = new FormData();
      form.append('_id', data._id);
      form.append('fileName', data.fileName);
      if (data.ktx) form.append('ktx', data.ktx.toString());
      form.append('order', data.order.toString());
      if (data.file) form.append('file', data.file);

      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.post<Result<void>>(
         `${environment.api}${WORLD.endpoint}/new/optFiles/${worldId}/${sceneId}`,
         form,
         { params }
      );
   }

   addCompFileNew(
      worldId: string,
      sceneId: string,
      data: _ComplementaryFiles & FirestoreDocument,
      owner?: string,
      ownerId?: string
   ) {
      const form = new FormData();
      form.append('_id', data._id);
      form.append('filename', data.fileName);
      form.append('lightMap', data.lightMap);
      form.append('oa', data.oa);
      form.append('resolution', data.resolution);
      if (data.ktxLightMap) form.append('ktxLightMap', data.ktxLightMap.toString());
      if (data.ktxAO) form.append('ktxAO', data.ktxAO.toString());
      form.append('order', data.order.toString());
      if (data.file) form.append('file', data.file);
      if (data.oaFile) form.append('oaFile', data.oaFile);
      if (data.lightMapFile) form.append('lightMapFile', data.lightMapFile);

      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.post<Result<void>>(
         `${environment.api}${WORLD.endpoint}/new/compFiles/${worldId}/${sceneId}`,
         form,
         { params }
      );
   }

   updateOptFileNew(
      worldId: string,
      sceneId: string,
      data: _OptionalFiles & FirestoreDocument,
      owner?: string,
      ownerId?: string
   ) {
      const form = new FormData();
      form.append('_id', data._id);
      form.append('filename', data.fileName);
      form.append('order', data.order.toString());
      if (data.ktx) form.append('ktx', data.ktx.toString());

      if (data.file) form.append('file', data.file);

      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.put<Result<void>>(
         `${environment.api}${WORLD.endpoint}/new/optFiles/update/${worldId}/${sceneId}/${data._id}`,
         form,
         { params }
      );
   }

   updateCompFileNew(
      worldId: string,
      sceneId: string,
      data: _ComplementaryFiles & FirestoreDocument,
      owner?: string,
      ownerId?: string
   ) {
      const form = new FormData();
      form.append('_id', data._id);
      form.append('filename', data.fileName);
      form.append('lightMap', data.lightMap);
      form.append('oa', data.oa);
      form.append('resolution', data.resolution);
      if (data.ktxLightMap) form.append('ktxLightMap', data.ktxLightMap.toString());
      if (data.ktxAO) form.append('ktxAO', data.ktxAO.toString());
      form.append('order', data.order.toString());
      if (data.file) form.append('file', data.file);
      if (data.oaFile) form.append('oaFile', data.oaFile);
      if (data.lightMapFile) form.append('lightMapFile', data.lightMapFile);

      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.put<Result<void>>(
         `${environment.api}${WORLD.endpoint}/new/compFiles/update/${worldId}/${sceneId}/${data._id}`,
         form,
         { params }
      );
   }

   getOptFileNew(
      worldId: string,
      sceneId: string,
      optFileId: string,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<_OptionalFiles | undefined>>(
         `${environment.api}${WORLD.endpoint}/new/optFiles/${worldId}/${sceneId}/${optFileId}`,
         { params }
      );
   }

   getCompFileNew(
      worldId: string,
      sceneId: string,
      compFileId: string,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<_ComplementaryFiles | undefined>>(
         `${environment.api}${WORLD.endpoint}/new/compFiles/${worldId}/${sceneId}/${compFileId}`,
         { params }
      );
   }

   deleteOptFileNew(
      worldId: string,
      sceneId: string,
      optFileId: string,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.delete<Result<void>>(
         `${environment.api}${WORLD.endpoint}/new/optFiles/${worldId}/${sceneId}/${optFileId}`,
         { params }
      );
   }

   deleteCompFileNew(
      worldId: string,
      sceneId: string,
      compFileId: string,
      owner?: string,
      ownerId?: string
   ) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.delete<Result<void>>(
         `${environment.api}${WORLD.endpoint}/new/compFiles/${worldId}/${sceneId}/${compFileId}`,
         { params }
      );
   }

   // ------- ENDPOINT FOR EDITOR (GET THE SCENE WITH COMP AND OPT FILES) ---------
   getSceneEditor(worldId: string, sceneId: string, owner?: string, ownerId?: string) {
      const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
      return this.http.get<Result<_Scene[] | null>>(
         `${environment.api}${WORLD.endpoint}/new/scene/${worldId}/${sceneId}`,
         { params }
      );
   }
}
