<div nz-row>
  <div nz-col [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
    <h2>{{ 'select-organization.title' | translate }}</h2>
  </div>
</div>

<form nz-form [formGroup]="group" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="organization">
      {{ 'common.organization' | translate }}
    </nz-form-label>
    <nz-form-control
      [nzSm]="14"
      [nzXs]="24"
      nzErrorTip="{{ 'assign-role.error-tips.organization-required' | translate }}"
    >
      <nz-select nzAllowClear nzShowSearch formControlName="organization" id="organization">
        <nz-option
          *ngFor="let item of organizations"
          [nzLabel]="item.name"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="role">
      {{ 'common.role' | translate }}
    </nz-form-label>
    <nz-form-control
      [nzSm]="14"
      [nzXs]="24"
      nzErrorTip="{{ 'assign-role.error-tips.role-required' | translate }}"
    >
      <nz-select nzAllowClear nzShowSearch formControlName="role" id="role">
        <nz-option *ngFor="let item of roles" [nzLabel]="item.name" [nzValue]="item"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
      <button nz-button nzType="primary" style="margin: 0 0.5rem 0.5rem 0">
        {{ 'common.select' | translate }}
      </button>
    </nz-form-control>
  </nz-form-item>
</form>

<app-spinner *ngIf="loading"></app-spinner>
