<div nz-row>
  <div nz-col [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
    <h2>{{ 'access-request.title' | translate }}</h2>
  </div>
</div>

<form nz-form [formGroup]="group" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">
      {{ 'common.email' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <input nz-input formControlName="email" id="email" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="duration">
      {{ 'common.duration' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-radio-group formControlName="duration" nzButtonStyle="solid">
        <label *ngFor="let option of durationOptions" nz-radio-button [nzValue]="option.value">
          {{ option.label }}
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="message">
      {{ 'common.message' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <textarea
        nz-input
        [nzAutosize]="{ minRows: 5, maxRows: 5 }"
        formControlName="message"
      ></textarea>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
      <button nz-button nzType="primary" style="margin: 0 0.5rem 0.5rem 0">
        {{ 'common.send' | translate }}
      </button>
    </nz-form-control>
  </nz-form-item>
</form>

<app-spinner *ngIf="loading"></app-spinner>
