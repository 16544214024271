import { Component, Input, OnInit } from '@angular/core';

import { Room } from '@features/models/room.model';
import { ProfileService } from '@features/services/profile.service';
import { FirestoreDocument } from '@shared/models/firestore-document';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent implements OnInit {
  loading = true;
  rooms: (Room & FirestoreDocument)[] = [];

  @Input() private id!: string;

  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {
    this.profileService.listRooms(this.id).subscribe({
      next: rooms => {
        this.loading = false;
        this.rooms = rooms.sort((a, b) => {
          const dateA = new Date(a.creationDate);
          const dateB = new Date(b.creationDate);
          return dateB.getTime() - dateA.getTime();
        });
      },
      error: error => {
        this.loading = false;
        console.log(error);
      }
    });
  }
}
