import { FormGroup } from '@angular/forms';

import { ORGANIZATION } from './global.constants';

export const formatString = (text: string, ...args: (string | number)[]) => {
  return text.replace(/{([0-9]+)}/g, (match, index) => args[index].toString() || match);
};

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};

export const makeOrganizationIsoPath = (id: string) => {
  return formatString(ORGANIZATION.isoPath, id);
};

export const makeOrganizationLogoPath = (id: string, extension: string | null | undefined) => {
  return extension
    ? formatString(ORGANIZATION.logoPath, id, extension)
    : formatString(ORGANIZATION.logoPathNoExt, id);
};

export const validateForm = (group: FormGroup) => {
  Object.values(group.controls).forEach(control => {
    if (control.invalid) {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    }
  });
};

export const makeHeaders = (collection?: string, collectionId?: string) => {
  let headers: { [header: string]: string };

  if (collection && collectionId) {
    switch (collection) {
      case 'profiles':
        headers = { 'Linkroom-Profile': collectionId };
        break;
      case 'organizations':
        headers = { 'Linkroom-Organization': collectionId };
        break;
      default:
        headers = {};
        break;
    }
  } else {
    headers = {};
  }
  return headers;
};
