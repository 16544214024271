import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { map, Observable } from 'rxjs';

import { MenuItem } from '../models/menu-item';
import { ResellerViewModel } from '../models/reseller.model';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { MENU, RESELLER } from '@shared/utility/global.constants';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private db: AngularFirestore, private localStorageService: LocalStorageService) {}

  add(data: MenuItem) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${MENU.collection}`;
    return this.db.collection<MenuItem>(path).add(data);
  }

  addOther(reseller: string, data: MenuItem & FirestoreDocument) {
    const path = `${RESELLER.collection}/${reseller}/${MENU.collection}`;

    return this.db.collection<MenuItem>(path).doc(data._id).set({
      content: data.content,
      icon: data.icon,
      order: data.order,
      parent: data.parent,
      permission: data.permission,
      route: data.route,
      submenu: data.submenu
    });
  }

  delete(id: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${MENU.collection}`;
    return this.db.collection<MenuItem>(path).doc(id).delete();
  }

  get(id: string): Observable<(MenuItem & FirestoreDocument) | undefined> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${MENU.collection}`;

    return this.db
      .collection<MenuItem>(path)
      .doc(id)
      .get()
      .pipe(
        map(snapshot => {
          const data = snapshot.data();
          return data ? { _id: snapshot.id, ...data } : undefined;
        })
      );
  }

  list(): Observable<(MenuItem & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${MENU.collection}`;

    return this.db
      .collection<MenuItem>(path)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  listChildren(): Observable<(MenuItem & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${MENU.collection}`;

    return this.db
      .collection<MenuItem>(path, ref => ref.where('parent', '!=', null))
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  listParents(): Observable<(MenuItem & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${MENU.collection}`;

    return this.db
      .collection<MenuItem>(path, ref => ref.where('parent', '==', null))
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  update(id: string, data: MenuItem) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${MENU.collection}`;
    return this.db.collection<MenuItem>(path).doc(id).update(data);
  }
}
