<div nz-row>
  <div nz-col [nzSm]="{ offset: 6, span: 24 }" [nzXs]="{ offset: 0, span: 24 }">
    <h2>{{ 'assign-user.title' | translate }}</h2>
  </div>
</div>

<form nz-form [formGroup]="group">
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="license">
      {{ 'common.license' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <input nz-input formControlName="license" id="license" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="user">
      {{ 'common.people' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a user" formControlName="user">
        <nz-option
          *ngFor="let item of listProfile"
          [nzLabel]="item.name"
          [nzValue]="item.name"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSm]="{ offset: 6, span: 14 }" [nzXs]="{ offset: 0, span: 24 }">
      <button nz-button nzType="primary" style="margin: 0 0.5rem 0.5rem 0">
        {{ 'common.save' | translate }}
      </button>
    </nz-form-control>
  </nz-form-item>
</form>

<app-spinner *ngIf="loading"></app-spinner>
