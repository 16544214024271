<div class="header">
  <h2 style="margin: 0">{{ 'common.rooms' | translate }}</h2>
</div>

<nz-table #roomsTable [nzData]="rooms" [nzLoading]="loading" nzSize="middle" [nzPageSize]="20">
  <thead>
    <tr>
      <th>{{ 'common.name' | translate }}</th>
      <th>{{ 'room.creation-date' | translate }}</th>
      <th>{{ 'room.last-opened' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of roomsTable.data">
      <td>{{ data.name }}</td>
      <td>{{ data.creationDate | date }}</td>
      <td>{{ data.lastAccessedDate | date }}</td>
    </tr>
  </tbody>
</nz-table>
