import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ResellerViewModel } from '../models/reseller.model';
import { OrganizationService } from '@features/services/organization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ORGANIZATION, RESELLER } from '@shared/utility/global.constants';

@Injectable()
export class ResellerInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private organizationService: OrganizationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    if (!reseller) return next.handle(request);

    const organization = this.organizationService.organization;

    const headers = organization
      ? request.headers
          .set(RESELLER.header, reseller._id)
          .set(ORGANIZATION.header, organization._id)
      : request.headers.set(RESELLER.header, reseller._id);

    const clone = request.clone({ headers });
    return next.handle(clone);
  }
}
