import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';

import { _Scene, SceneViewModel } from '@features/models/world.model';
import { SkyboxService } from '@features/services/skybox.service';
import { WorldService } from '@features/services/world.service';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-skybox-delete',
  templateUrl: './skybox-delete.component.html',
  styleUrls: ['./skybox-delete.component.scss']
})
export class SkyboxDeleteComponent implements OnInit {
  @Input() skyboxId!: string;
  @Input() owner!: string;
  @Input() ownerId!: string;

  scenes: SceneViewModel[] = [];

  tableLoading = false;
  loading = false;

  constructor(
    private router: Router,
    private worldService: WorldService,
    private modalService: ModalService,
    private skyboxService: SkyboxService,
    private translate: TranslateService,
    private modalRef: NzModalRef
  ) {}

  ngOnInit(): void {
    this.initTable();
  }

  async initTable() {
    this.tableLoading = true;
    const { data } = await lastValueFrom(this.worldService.getScenesWithSkybox(this.skyboxId));
    this.scenes = data;

    this.tableLoading = false;
  }

  async onClick(sceneId: string) {
    this.loading = true;
    const { data } = await lastValueFrom(this.worldService.GetWorldInScene(sceneId));

    const queryParams: any = {
      owner: data.owner,
      ownerId: data.ownerId
    };

    const navigationExtras: NavigationExtras = {
      queryParams: queryParams
    };

    this.loading = false;
    this.router.navigate(['scenes', data._id, sceneId, 'edit'], navigationExtras);
    this.modalRef.close();
  }

  async onDelete() {
    try {
      this.loading = true;
      await lastValueFrom(
        this.skyboxService.deleteSkyboxNew(this.skyboxId, this.owner, this.ownerId)
      );
      this.loading = false;
      this.initTable();
      this.modalRef.close();
    } catch (error) {
      this.translate
        .get('Error')
        .subscribe((translation: string) => this.modalService.onError(translation));
      this.loading = false;
    }
  }
}
