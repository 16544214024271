import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import firebase from 'firebase/compat/app';
import { lastValueFrom, startWith, Subject, take } from 'rxjs';

import { ProfileViewModel } from '@features/models/profile.model';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { Result } from '@shared/models/result.model';
import { AUTH } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _permissions: string[] = [];
  private _permissions$ = new Subject<void>();
  private _user?: ProfileViewModel;
  private _user$ = new Subject<ProfileViewModel | undefined>();

  get permissions() {
    return this._permissions;
  }

  get permissions$() {
    return this._permissions$.asObservable();
  }

  get user() {
    return this._user;
  }

  get user$() {
    return this._user$.pipe(startWith(this._user));
  }

  constructor(
    private auth: AngularFireAuth,
    private http: HttpClient,
    private organizationService: OrganizationService,
    private profileService: ProfileService
  ) {
    this.initData();
  }

  initData() {
    this.auth.user.pipe(take(1)).subscribe(user => {
      if (user) {
        this.profileService.get(user.uid).subscribe({
          next: async response => {
            if (response.data) {
              this._user = response.data;
              this._user$.next(response.data);
            } else {
              this._user = undefined;
              this._user$.next(undefined);
            }
          },
          error: error => {
            this._user = undefined;
            this._user$.next(undefined);
          }
        });
      } else {
        this._user = undefined;
        this._user$.next(undefined);
      }
    });
  }

  hasPermission(permission: string) {
    if (this.user?.sa && this._permissions.length < 1) return true;
    if (this.permissions.includes(permission)) return true;
    return false;
  }

  facebookSignIn() {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({ redirect_uri: environment.authDomain });
    return this.signInWithPopup(provider);
  }

  googleSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ redirect_uri: environment.authDomain });
    provider.addScope('email');
    provider.addScope('profile');
    return this.signInWithPopup(provider);
  }

  async refresh() {
    this._user$.next(this._user);
  }

  resetPassword(email: string) {
    return this.http.post<Result<string>>(`${environment.api}${AUTH.endpoint}/reset-password`, {
      email
    });
  }

  confirmPasswordReset(code: string, newPassword: string) {
    return this.auth.confirmPasswordReset(code, newPassword);
  }

  setPermissions(permissions: string[]) {
    this._permissions = permissions;
    this._permissions$.next();
  }

  signIn(email: string, password: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const credential = await this.auth.signInWithEmailAndPassword(email, password);

        if (credential.user) {
          const user = credential.user;

          this.profileService.get(user.uid).subscribe({
            next: async response => {
              if (response.data) {
                if (!response.data.active) {
                  await this.signOut();
                  reject('Your account is not active. Please contact your administrator.');
                } else {
                  this._user = response.data;
                  this._user$.next(response.data);
                  resolve();
                }
              } else {
                const response = await lastValueFrom(
                  this.profileService.add({
                    _id: user.uid,
                    email: user.email || '',
                    firstName: user.displayName || '',
                    lastName: ''
                  })
                );

                this._user = response.data;
                this._user$.next(response.data);
                resolve();
              }
            },
            error: async error => {
              await this.auth.signOut();
              reject(error);
            }
          });
        } else {
          reject();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  signOut() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.auth.signOut();
        this._user = undefined;
        this._user$.next(undefined);
        await this.organizationService.select(null, { navigate: false, signOut: true });
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

  signUp(email: string, password: string, firstName: string, lastName: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const credential = await this.auth.createUserWithEmailAndPassword(email, password);

        if (credential.user) {
          const user = credential.user;

          const response = await lastValueFrom(
            this.profileService.add({
              _id: user.uid,
              email: user.email || '',
              firstName: firstName,
              lastName: lastName
            })
          );

          this._user = response.data;
          this._user$.next(response.data);
          resolve();
        } else {
          reject();
        }
      } catch (error) {
        reject();
      }
    });
  }

  private signInWithPopup(provider: firebase.auth.AuthProvider) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const credential = await this.auth.signInWithPopup(provider);

        if (credential.user) {
          const user = credential.user;

          this.profileService.get(user.uid).subscribe({
            next: async response => {
              if (response.data) {
                if (!response.data.active) {
                  await this.signOut();
                  reject('Your account is not active. Please contact your administrator.');
                } else {
                  this._user = response.data;
                  this._user$.next(response.data);
                  resolve();
                }
              } else {
                const response = await lastValueFrom(
                  this.profileService.add({
                    _id: user.uid,
                    email: user.email || '',
                    firstName: user.displayName || '',
                    lastName: ''
                  })
                );

                this._user = response.data;
                this._user$.next(response.data);
                resolve();
              }
            },
            error: error => {
              this.auth.signOut();
              reject();
            }
          });
        } else {
          reject();
        }
      } catch (error) {
        reject();
      }
    });
  }
}
