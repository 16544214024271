import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { map, Observable } from 'rxjs';

import { InviteViewModel } from '../models/invite.model';
import { SetOrganizationDto } from '../models/organization.model';
import {
  _Organization,
  AddProfileDto,
  LicenseDto,
  Profile,
  ProfileViewModel
} from '../models/profile.model';
import { Room } from '../models/room.model';
import { ResellerViewModel } from '@core/models/reseller.model';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { Result } from '@shared/models/result.model';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ORGANIZATION, PROFILE, RESELLER, ROOM } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  add(data: AddProfileDto) {
    return this.http.post<Result<ProfileViewModel>>(`${environment.api}${PROFILE.endpoint}`, data);
  }

  addLicense(data: LicenseDto) {
    return this.http.put<Result<void | undefined>>(
      `${environment.api}${PROFILE.endpoint}/license`,
      data
    );
  }

  deleteOrganization(profile: string, organization: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${PROFILE.collection}`;

    return this.db
      .collection<Profile>(path)
      .doc(profile)
      .collection<_Organization>(ORGANIZATION.collection)
      .doc(organization)
      .delete();
  }

  get(id: string) {
    return this.http.get<Result<ProfileViewModel | undefined>>(
      `${environment.api}${PROFILE.endpoint}/${id}`
    );
  }

  getId(email: string) {
    return this.http.get<Result<string | null>>(
      `${environment.api}${PROFILE.endpoint}/email/${email}`
    );
  }

  getOrganization(
    profile: string,
    organization: string
  ): Observable<(_Organization & FirestoreDocument) | undefined> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${PROFILE.collection}`;

    return this.db
      .collection<Profile>(path)
      .doc(profile)
      .collection<_Organization>(ORGANIZATION.collection)
      .doc(organization)
      .get()
      .pipe(
        map(snapshot => {
          const data = snapshot.data();
          return data ? { _id: snapshot.id, ...data } : undefined;
        })
      );
  }

  haveOrg(id: string | null, organizationId: string) {
    return this.http.get<Result<boolean>>(
      `${environment.api}${PROFILE.endpoint}/org/${id}/${organizationId}`
    );
  }

  isInOrganization(profileId: string | null, organizationId: string) {
    return this.http.get<Result<boolean>>(
      `${environment.api}${PROFILE.endpoint}/org/${profileId}/${organizationId}`
    );
  }

  list() {
    return this.http.get<Result<ProfileViewModel[]>>(`${environment.api}${PROFILE.endpoint}`);
  }

  listInvites(email: string | undefined) {
    return this.http.get<Result<InviteViewModel[]>>(
      `${environment.api}${PROFILE.endpoint}/invites/${email}`
    );
  }

  updateOrgName(orgId: string, organizationName: string) {
    return this.http.put<Result<void>>(
      `${environment.api}${PROFILE.endpoint}/organization/${orgId}/${organizationName}`,
      {}
    );
  }

  listOrganizations(id: string): Observable<(_Organization & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${PROFILE.collection}`;

    return this.db
      .collection<Profile>(path)
      .doc(id)
      .collection<_Organization>(ORGANIZATION.collection)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  listRooms(id: string): Observable<(Room & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${PROFILE.collection}`;

    return this.db
      .collection<Profile>(path)
      .doc(id)
      .collection<Room>(ROOM.collection)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  setOrganization(profile: string, dto: SetOrganizationDto) {
    return this.http.post(
      `${environment.api}${PROFILE.endpoint}/${profile}${ORGANIZATION.endpoint}`,
      dto
    );
  }

  update(id: string, data: Profile) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${PROFILE.collection}`;

    return this.db.collection<Profile>(path).doc(id).update(data);
  }

  updateIdCustomer(id: string, customerId: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${PROFILE.collection}`;

    return this.db.collection<Profile>(path).doc(id).update({ customerId: customerId });
  }

  removeLicense(data: LicenseDto) {
    return this.http.put<Result<void | undefined>>(
      `${environment.api}${PROFILE.endpoint}/license/remove`,
      data
    );
  }
}
