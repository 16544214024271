<div class="header" style="margin-bottom: 1rem">
  <h2 style="margin: 0">{{ 'scenes.unassign' | translate }}</h2>
</div>

<nz-table
  #scenesTable
  [nzData]="scenes"
  [nzLoading]="tableLoading"
  nzSize="middle"
  [nzPageSize]="20"
  style="margin: 1rem 0"
>
  <thead>
    <tr>
      <th>{{ 'scenes.name' | translate }}</th>
      <th>{{ 'scenes.unassign-skybox' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of scenesTable.data">
      <td>{{ data.name }}</td>
      <td>
        <button nz-button nzType="primary" (click)="onClick(data._id)">
          <i nz-icon nzType="delete"></i>
          {{ 'scenes.unassign-skybox' | translate }}
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<nz-divider nzType="horizontal"></nz-divider>

<div class="header" style="margin-bottom: 1rem">
  <h2 style="margin: 0">{{ 'scenes.delete-skybox-title' | translate }}</h2>
  <button
    nz-button
    nzType="primary"
    nzDanger
    [disabled]="scenes.length > 0 || tableLoading"
    [nzLoading]="tableLoading"
    (click)="onDelete()"
  >
    <i nz-icon nzType="delete"></i>
    {{ 'scenes.delete-skybox' | translate }}
  </button>
</div>

<app-spinner *ngIf="loading"></app-spinner>
