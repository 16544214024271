import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { from, lastValueFrom, take } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const user = await lastValueFrom(this.auth.user.pipe(take(1)));
    if (!user) return await lastValueFrom(next.handle(request));

    const token = await user.getIdToken();
    const clone = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
    return await lastValueFrom(next.handle(clone));
  }
}
