import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalRef } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';

import { AccessRequestDto } from '@features/models/access-request.model';
import { AccessRequestService } from '@features/services/access-request.service';
import { validateForm } from '@shared/utility/global.functions';

interface DurationOption {
  label: string;
  value: number;
}

@Component({
  selector: 'app-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.scss']
})
export class AccessRequestComponent implements OnInit {
  durationOptions: DurationOption[] = [
    { label: '15m', value: 15 * 60 * 1000 },
    { label: '1h', value: 1 * 60 * 60 * 1000 },
    { label: '4h', value: 4 * 60 * 60 * 1000 },
    { label: '8h', value: 8 * 60 * 60 * 1000 }
  ];

  group!: FormGroup;
  loading = false;

  @Input() private email!: string;
  @Input() private id!: string;

  constructor(
    private accessRequestService: AccessRequestService,
    private builder: FormBuilder,
    private modalRef: NzModalRef
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  async onSubmit() {
    if (this.group.valid) {
      this.loading = true;

      const data: AccessRequestDto = {
        duration: this.group.value.duration,
        message: this.group.value.message,
        profile: this.id
      };

      await lastValueFrom(this.accessRequestService.add(data));
      this.modalRef.close('ok');
    } else {
      validateForm(this.group);
    }
  }

  private initForm() {
    this.group = this.builder.group({
      duration: [15 * 60 * 1000, [Validators.required, Validators.min(15 * 60 * 1000)]],
      email: [{ value: this.email, disabled: true }],
      message: ['']
    });
  }
}
